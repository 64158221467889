// #region imports
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../service/UserService';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, PatternValidator, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, DateFormatter } from 'ngx-bootstrap/datepicker';
import { DatePipe, formatDate } from '@angular/common';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { format } from 'util';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { Globals } from '../Globals'

// #endregion

@Component({
  selector: 'app-in-outward',
  templateUrl: './in-outward.component.html',
  styleUrls: ['./in-outward.component.css'],
  providers: [NgbAccordionConfig, DatePipe]
})
export class InOutwardComponent implements OnInit {
  title = 'htmltopdf';
  @ViewChild('pdfTable') pdfTable: ElementRef;

  //#region global Variable Declaration
  public Log_UserID: number = 0;
  public Log_BoardID: number = 0;
  //public Log_accessFor: string = '';

  active;
  disabled = true;
  

  inoutDate;
  //DocDt:Date = new Date();
  DocDt;
  isInSel: boolean = true;
  fileInfo: string = '';
  fileserchCnt: string = 'yes';

  searchFile: string = '';
  filter_file: any = [];
  InOutFlag: string = 'InWard';
  InwardSelect: string = ''
  OutWardSelect: string = ''
  bsdateconfig: Partial<BsDatepickerConfig>;

  showModal_file: boolean;
  mdlFileNo: string = '';
  mdlFilenanme: string = '';
  mdlFiletype: string = ''
  BindFileList: any = [];

  public selectedSearchFileID: number = 0;
  public selectedSearchFileNo: number = 0;
  public selectedSearchFileName: string = "";
  public selectedSearchFileFlag: string = "";

  InOutWardRefList: any = [];
  ActiveUserList: any = [];
  forwardUserID: number = 0;
  SendByUserID: number = 0;

  public InOutWardID: number = 0;
  public InOutWardActionList: any = [];

  AddressTo: string = '';
  FromWhom: string = '';
  InOutAddress: string = '';
  Subject: string = '';
  description: string = '';
  delivaryType: string = '';
  ActionTaken: string = '';
  forwardRemark: string = '';
  AttacheFile: any;
  InOutNo: number = 0;
  SendDate;

  InOutRefDateList: any = [];
  InOutRefNoList: any = [];
  InOutRefAddressToList: any = [];
  InOutRefFromWhomList: any = [];
  InOutRefAddressList: any = [];
  InOutRefSubjectList: any = [];

  refInOutdate: Date;
  refInOutNo: number = 0;
  refInOutAddressTo: string = "";
  refInOutFromWhom: string = "";
  refInOutAddress: string = "";
  refInOutSubject: string = "";

  refInOutfilterList: any = [];
  maxAsOnDate;
  PreviousDashBoard:string='/dashboard/clerk-dashboard'
  recFrom:string='';
  public SearchInOutList:any=[];
  public SearchInOutID:number=0;
  public rbtnInwardchekd:any=true;
  public rbtnOutwardchekd:any=false;

  public InOutActionID:number=0;
  //public txtInOutNo:any = '';
  //#endregion

  //#region Constructor Defination
  constructor(
    private calendar: NgbCalendar,
    config: NgbAccordionConfig,
    public http: Http,
    private _userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private globals: Globals) {
    config.closeOthers = true;
    config.type = 'info';
  }
  //#endregion

  //#region ngOnIt
  ngOnInit(): void {
    this.bsdateconfig = Object.assign({},
      {
        containerClass: 'theme-dark-blue',
        showWeekNumbers: false,
        dateInputFormat: 'DD/MM/YYYY'
      });

      this.DocDt = new Date().toISOString().substring(0, 10);
      this.inoutDate = new Date().toISOString().substring(0, 10);
      this.SendDate = new Date().toISOString().substring(0, 10);
      this.maxAsOnDate = new Date().toISOString().substring(0, 10);
    // tslint:disable-next-line: triple-equals
    if (sessionStorage.getItem('mathaLog_UserID') != null && sessionStorage.getItem('mathaLog_UserID') != '') {
      this.Log_UserID = Number(sessionStorage.getItem('mathaLog_UserID'));
    }
    // tslint:disable-next-line: triple-equals
    if (sessionStorage.getItem('mathaLog_BoardID') != null && sessionStorage.getItem('mathaLog_BoardID') != '') {
      this.Log_BoardID = Number(sessionStorage.getItem('mathaLog_BoardID'));
    }

    this.getBoardWiseAllActiveUser();

   
  }
  //#endregion

  //#region Bind InOutWard Master
    Bind_InOutMaster(){
      this.refInOutfilterList = [];
      this.show_loadingModal(true);
      const url="api/" + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/BindInOutMaster/'+this.InOutWardID;
      this._userService.get_Data(url).subscribe((data)=>{
        if(data.length>0){
         // console.log('data',data);
          this.show_loadingModal(false);
            //this.InOutFlag =data[0].flag;
            if (data[0].flag=='InWard'){
              this.isInSel=true;
            }else{
              this.isInSel=false;
            }
         
            let date: Date;
            date = new Date(data[0].inOutDt);
            date.setDate(date.getDate() + 1);
            this.inoutDate = new Date(date).toISOString().substring(0, 10);;

            //this.inoutDate = data[0].inOutDt;

           // this.DocDt=data[0].docDt;

           
           date = new Date(data[0].docDt);
           date.setDate(date.getDate() + 1);
           this.DocDt = new Date(date).toISOString().substring(0, 10);

          //  date = new Date(data[0].inOutDt);
          //  date.setDate(date.getDate() + 1);

           //this.inoutDate = date;

           //this.DocDt=data[0].docDt;

           this.InOutNo=data[0].inOutNo;

            this.AddressTo=data[0].addressTo;
            this.FromWhom=data[0].fromWhom;
            this.InOutAddress=data[0].inOuterAdd;
            this.Subject=data[0].subject
            this.description=data[0].description;
            this.delivaryType=data[0].deliveryType;
            this.setSearchFile(data[0].fileID,data[0].fileNo,data[0].fileName,data[0].fileflag);


           
            if (data[0].refInOutflag!=""){
              if (data[0].refInOutflag=='InWard'){
                this.rbtnInwardchekd=true;
                this.rbtnOutwardchekd=false;
              }else if (data[0].refInOutflag=='OutWard'){
                this.rbtnInwardchekd=false;
                this.rbtnOutwardchekd=true;
              }

//console.log('this.InOutWardRefList',this.InOutWardRefList);

              this.InOutRefSelect(data[0].refInOutflag);  
              setTimeout(() => {
                this.refInOutfilterList=this.InOutWardRefList.filter(r=>Number(r.inOutID)==Number(data[0].refInOutID))
                if (Number(this.refInOutfilterList.length) > 0){
                 // console.log('refInOutfilterList',this.refInOutfilterList);
                    for (let c = 0; c < this.refInOutfilterList.length; c++) {


                      this.refInOutfilterList[c]["ischecked"] = true;
                    }
                }
              }, 500);
            }

           
           
            this.getInOutActionHistroy();
        }
        
      },
      (err: HttpErrorResponse) => {
        //alert(err);
        this.show_loadingModal(false);
        alert('Something went wrong');
         return;
      });
    }
  //#endregion

  //#region InOutSelect 
  InOutSelect(SelectedValue: string) {
    this.InOutFlag = SelectedValue;
    if (SelectedValue=="OutWard"){
      this.forwardUserID=0;
      this.ActionTaken="";
      this.forwardRemark="";
    }
    this.Fill_InOutSearch(SelectedValue);
  }
  //#endregion

  //#region fill InOut Filter
  Fill_InOutSearch(SelectedValue: string){
    this.SearchInOutList=[];
    const url ="api/"+this._userService.get_InwardOutWard_controlerName(this.Log_BoardID)+'/InOutSearch/'+ this.Log_UserID +'/'+SelectedValue;
    this._userService.get_Data(url).subscribe((data)=>{
      this.SearchInOutList=data;
    });
  }

  //#endregion

  //#region Insert Update File Master
  disable_filebupdbtn = false;
  InsertUpdateFileMaster() {
    this.disable_filebupdbtn = true;
    if (this.mdlFilenanme == "") {
      alert('Enter File Name');
      this.disable_filebupdbtn = false;
      return;
    }

    if (this.mdlFiletype == "Select File Type" || this.mdlFiletype == "") {
      alert('Select File Type');
      this.disable_filebupdbtn = false;
      return;
    }

    let InOutWard = {
      'FileID': 0,
      'FileName': this.mdlFilenanme,
      'FileNo': this.mdlFileNo,
      'Flag': this.mdlFiletype
    };



    this.show_loadingModal(true);
    const url = 'api/' + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/InsertUpdateFileMaster';
    this._userService.post_data(url, InOutWard).subscribe((data) => {
      if (data == "Error") {
        this.show_loadingModal(false);
        this.disable_filebupdbtn = false;
        alert('There is some Problem! contact to administrator')
      } else {
        this.show_loadingModal(false);
        alert(data);
        this.disable_filebupdbtn = false;
        this.hide_modal_file();
        this.BindFileMaster();

      }
    },
    (err: HttpErrorResponse) => {
      this.disable_filebupdbtn = false;
      this.show_loadingModal(false);
      alert('Something went wrong');
       return;
    });
  }
  //#endregion

  //#region Show File Modal Popup
  show_modal_file() {
    this.showModal_file = true; // Show-Hide Modal Check
  }
  //#endregion

  //#region Hide Modal Popup
  hide_modal_file() {
    this.showModal_file = false;
    this.mdlFileNo = '';
    this.mdlFilenanme = '';
    this.mdlFiletype = '';
  }
  //#endregion

  //#region Bind file Master
  BindFileMaster() {
    this.show_loadingModal(true);
    this.BindFileList = [];
    const url = "api/" + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/BindFileMaster'
    this._userService.get_Data(url).subscribe((data) => {
      //console.log('this.BindFileList',data);
      this.BindFileList = data;
      this.show_loadingModal(false);
    },
    (err: HttpErrorResponse) => {
      //alert(err);
      this.show_loadingModal(false);
      alert('Something went wrong');
       return;
    });
  }

  //#endregion

  //#region Search File By Name
  SearchFileByName() {
    this.filter_file = [];
    this.filter_file = this.BindFileList.filter(r => String(r.fileName).toLowerCase().startsWith(this.searchFile.toLowerCase()) && r.flag == (this.InOutFlag == 'InWard' ? 'In' : 'Out'));
  }
  //#endregion

  //#region Set Search File 
  setSearchFile(serfileID: number, serfileNo: number, serfileName: string, serflag: string) {
    this.selectedSearchFileID = serfileID;
    this.selectedSearchFileNo = serfileNo;
    this.selectedSearchFileName = serfileName;
    this.searchFile = String(serfileNo) + ' - ' + serfileName;
    this.selectedSearchFileFlag = serflag;
    this.filter_file = [];
  }
  //#endregion

  //#region InOutRefSelect
  InOutRefSelect(InOutRef: string) {
    this.refInOutfilterList = [];
    this.InOutWardRefList = [];
    this.InOutRefDateList = [];
    this.InOutRefNoList = [];
    this.InOutRefAddressToList = [];
    this.InOutRefFromWhomList = [];
    this.InOutRefAddressList = [];
    this.InOutRefSubjectList = [];

    const url = 'api/' + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/getInOutWardRef/' + InOutRef;
    this._userService.get_Data(url).subscribe((data) => {
      this.InOutWardRefList = data;
      this.InOutRefDateList = this.InOutWardRefList.map(r => r.inOutDt).filter((value, index, self) => self.indexOf(value) == index);

    });
  }
  //#endregion

  //#region get BoardWise All Active User
  getBoardWiseAllActiveUser() {

    this.show_loadingModal(true);

    this.SendByUserID = this.Log_UserID;
    const url = "getBoardWiseAllActiveUser/" + this.Log_BoardID;
    this._userService.get_Dashborad_Data(url).subscribe((data) => {
      this.ActiveUserList = [];
      this.ActiveUserList = data;
      //console.log('user-lis',data)

      this.show_loadingModal(false);

      this.InOutWardID = Number(this.route.snapshot.queryParamMap.get('RecNo'));
      this.recFrom=String(this.route.snapshot.queryParamMap.get('RecFrom'));

          if (String(this.route.snapshot.queryParamMap.get('Dashboard')) != null && String(this.route.snapshot.queryParamMap.get('Dashboard')) != ''){
            this.PreviousDashBoard = String(this.route.snapshot.queryParamMap.get('Dashboard'));
          }
          else{
            this.PreviousDashBoard = '';
          }

      //console.log('RecFrom',this.recFrom);
  
      if (this.InOutWardID>0){
        this.Bind_InOutMaster();
      } else {
        this.Fill_InOutSearch('InWard');
        this.BindFileMaster();
        this.InOutRefSelect('InWard');
      }
    },
    (err: HttpErrorResponse) => {
      //alert(err);
      this.show_loadingModal(false);
      alert('Something went wrong');
       return;
    });
  }
  //#endregion

  //#region getInOutActionHistroy
  getInOutActionHistroy() {
    this.InOutWardActionList = [];
    const url = "api/" + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/getInOutActionHistory/' + this.InOutWardID;
    this._userService.get_Data(url).subscribe((data) => {
      this.InOutWardActionList = data;
      //console.log('actuser',this.ActiveUserList);
      
      if (this.InOutWardActionList.length > 0) {
        this.InOutWardActionList.forEach(e => {
          e['sendByName']= this.ActiveUserList.filter(r => Number(r.userID) == Number(e.sendByID)).map(r => r.userName)[0];
        });
       // console.log('actlist',this.InOutWardActionList);
      }
    });
  }
  //#endregion

  //#region save Inward OutWard
  Save() {
    if (Number(this.InOutNo) < 0){
      alert('Enter Valid ' + this.InOutFlag +' No.');
      return
    }
    if (this.invalid_inOutDate == 'invalid'){
      alert('Enter Valid Inward/Outward Date');
      return
    }
    if (this.invalid_DocDate == 'invalid'){
      let today_Date = new Date().toISOString().substring(0, 10);
        if (this.DocDt > today_Date) {
          alert('Enter Doc. Date <= Today');
        }
        else{
          alert('Enter Valid Doc. Date');
        }
      
      return
    }
    if (this.invalid_SendDate == 'invalid'){
      alert('In Valid Send Date');
      return
    }

    if (this.AddressTo.trim() == '') {
      alert('Please Enter Address To');
      return
    }

    if (this.FromWhom.trim() == '') {
      alert('Please Enter From Whom');
      return
    }
    if (this.InOutAddress.trim() == '') {
      alert('Please Enter Address');
      return
    }
    if (this.Subject.trim() == '') {
      alert('Please Enter Subject');
      return
    }
    if (this.delivaryType.trim() == '') {
      alert('Please Enter Delivary Type');
      return
    }

    if (this.selectedSearchFileID == 0) {
      alert('Please search and Select File');
      return;
    }

    if (this.InOutFlag == "InWard") {
      if (this.ActionTaken.trim() == '') {
        alert('Please Enter Action Taken By');
        return
      }
      if (this.SendByUserID == 0) {
        alert('Please Select Send By');
        return
      }

      if (this.forwardUserID == 0) {
        alert('Please Select Forward to');
        return
      }
    }
   
    if (Number(this.InOutWardID) > 0) {
    }
    else {
      if (this.AttacheFile == undefined || this.AttacheFile.length == 0 || this.AttacheFile["name"].trim() == "") {
        alert("Please Attache Document file");
        return;
      }
     
    }


    if (String(this.AttacheFile["name"]).substr(this.AttacheFile["name"].lastIndexOf(".") + 1) == "webp") {
     // alert("Please Chanage Attachement File.Attachment File Type is wrong");
     alert('File format should be .xls,.xlsx,.txt,.pdf,.png,.jpg,.jpeg');
      return;
    }

    let flext:string = String(this.AttacheFile["name"]).substr(this.AttacheFile["name"].lastIndexOf(".") + 1)

      if (flext.toLocaleLowerCase() == 'xls' || flext.toLocaleLowerCase() == 'xlsx' || flext.toLocaleLowerCase() == 'txt' || flext.toLocaleLowerCase() == 'pdf' || flext.toLocaleLowerCase() == 'png' || flext.toLocaleLowerCase() == 'jpg' || flext.toLocaleLowerCase() == 'jpeg'){
        
      }
      else{
        alert('File format should be .xls,.xlsx,.txt,.pdf,.png,.jpg,.jpeg');
        return;
      }

    let refInOutWardID: number = 0;
    let selectedRefRec = this.refInOutfilterList.filter(e => e.ischecked == true);
    
    if (selectedRefRec.length > 1) {
      alert('You can only Add one Reference');
      return;
    } else if (selectedRefRec.length == 1) {
      refInOutWardID = selectedRefRec[0].inOutID;
    }

    let InOutMaster: any = [{
      InOutID: this.InOutWardID,
      InOutNo: this.InOutNo,
      InOutDt: formatDate(this.inoutDate, "dd/MM/yyyy", "en-us"),
      AddressTo: this.AddressTo,
      FromWhom: this.FromWhom,
      InOuterAdd: this.InOutAddress,
      Subject: this.Subject,
      Description: this.description,
      Flag: this.InOutFlag,
      DocDt: formatDate(this.DocDt, "dd/MM/yyyy", "en-us"),
      FileID: this.selectedSearchFileID,
      RefInOutID: refInOutWardID,
      UserID: this.Log_UserID,
      CompanyID: 1,
      DeliveryType: this.delivaryType
    }];

    let InOutAction: any = [{
      InOutActionID: this.InOutActionID,
      InOutID: this.InOutWardID,
      ConcernID: this.forwardUserID,
      ActionTaken: this.ActionTaken,
      SendByID: this.SendByUserID,
      SendDate: formatDate(this.SendDate, "dd/MM/yyyy", "en-us"),
      Remark: this.forwardRemark,
      Status: "Active"
    }];

    let InOutDt: any = [{
      InOutWardDtID: 0,
      InOutID: this.InOutWardID,
      ScanImg: this.AttacheFile["name"],
      FileName: this.AttacheFile["name"],
      FileType: ""
    }];

    
   

    this.ngxService.stop();

    const formData = new FormData();
    formData.append('InOutMaster', JSON.stringify(InOutMaster));
    formData.append('InOutAction', JSON.stringify(InOutAction));
    formData.append('InOutDt', JSON.stringify(InOutDt));
    formData.append(this.AttacheFile["name"], this.AttacheFile, this.AttacheFile["name"]);
    

    this.show_loadingModal(true);

    const url = 'api/' + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/SaveInwardOutWard';
    this._userService.saveFormData(formData, url).subscribe((data) => {
      if (data == "Record Saved Sucessfully") {
        this.clear_form();
      }
      setTimeout(() => {
        alert(data);
      }, 500);
      this.ngxService.stop()
    },
    (err: HttpErrorResponse) => {
      //alert(err);
      this.show_loadingModal(false);
      alert('Something went wrong');
       return;
    });
  }
  //#endregion

  //#region Set Attach file
  setAttachFile(event: any) {
    this.AttacheFile = [];
    if (event.target.files.length > 0) {
      this.AttacheFile = event.target.files[0];
      
      // console.log('filename', this.AttacheFile.fileName);
      // console.log('size', this.AttacheFile.size);
      // console.log('type', this.AttacheFile.type);
      // console.log(event.target.files[0].name.split(".").pop());

    }
  }
  //#endregion

  //#region Filter InWard OutWard Filter List
  filterRefList() {
    this.refInOutfilterList = [];
    if ((String(this.refInOutdate) != "" && this.refInOutdate != undefined) || this.refInOutNo != 0 || this.refInOutAddressTo != "" || this.refInOutFromWhom != "" || this.refInOutAddress != "" || this.refInOutSubject != "") {
      this.refInOutfilterList = [];
      this.refInOutfilterList = this.InOutWardRefList.filter(rec => formatDate(rec.inOutDt, "dd/MM/yyyy", "en-us") == (String(this.refInOutdate) == "" || this.refInOutdate == undefined ? formatDate(rec.inOutDt, "dd/MM/yyyy", "en-us") : this.refInOutdate) &&
        rec.inOutNo == (this.refInOutNo == 0 ? rec.inOutNo : this.refInOutNo) && rec.addressTo.toLowerCase().includes(this.refInOutAddressTo == "" ? rec.addressTo.toLowerCase() : this.refInOutAddressTo.toLowerCase()) &&
        rec.fromWhom.toLowerCase().includes(this.refInOutFromWhom == "" ? rec.fromWhom.toLowerCase() : this.refInOutFromWhom.toLowerCase()) && rec.inOuterAdd.toLowerCase().includes(this.refInOutAddress == "" ? rec.inOuterAdd.toLowerCase() : this.refInOutAddress.toLowerCase()) &&
        rec.subject.toLowerCase().includes(this.refInOutSubject == "" ? rec.subject.toLowerCase() : this.refInOutSubject.toLowerCase()));


      // console.log('this.InOutRefDateList---',this.refInOutfilterList);
          if (Number(this.InOutWardID) > 0){
            this.refInOutfilterList = this.refInOutfilterList.filter(x => x.inOutID != Number(this.InOutWardID));
          }
        

      this.refInOutfilterList.forEach(e => {
        ischecked: false;
      });
    }
  }
  //#endregion

  //#region Clear Text
  clear_form() {
    this.inoutDate = new Date();
    this.DocDt = new Date();
    this.isInSel = true;
    this.fileInfo = '';
    this.fileserchCnt = 'yes';

    this.searchFile = '';
    this.filter_file = [];
    this.InOutFlag = 'InWard';
    let rbtnInward= document.getElementById("rbtnInwardRef") as HTMLInputElement;
    rbtnInward.checked=true;
    this.rbtnInwardchekd=true;
    this.rbtnOutwardchekd=false;
    this.Fill_InOutSearch('InWard');
    this.InwardSelect = '';
    this.OutWardSelect = '';
    this.showModal_file = false;
    this.mdlFileNo = '';
    this.mdlFilenanme = '';
    this.mdlFiletype = '';
    this.BindFileList = [];

    this.selectedSearchFileID = 0;
    this.selectedSearchFileNo = 0;
    this.selectedSearchFileName = "";
    this.selectedSearchFileFlag = "";

    this.InOutWardRefList = [];
    this.ActiveUserList = [];
    this.forwardUserID = 0;
    this.SendByUserID = 0;

    this.InOutWardID = 0;
    this.InOutWardActionList = [];

    this.AddressTo = '';
    this.FromWhom = '';
    this.InOutAddress = '';
    this.Subject = '';
    this.description = '';
    this.delivaryType = '';
    this.ActionTaken = '';
    this.forwardRemark = '';
    this.AttacheFile = [];
    this.InOutNo = 0;
    this.SendDate;
    this.InOutRefDateList = [];
    this.InOutRefNoList = [];
    this.InOutRefAddressToList = [];
    this.InOutRefFromWhomList = [];
    this.InOutRefAddressList = [];
    this.InOutRefSubjectList = [];

    //this.refInOutdate="";
    this.refInOutNo = 0;
    this.refInOutAddressTo = "";
    this.refInOutFromWhom = "";
    this.refInOutAddress = "";
    this.refInOutSubject = "";
    this.refInOutfilterList = [];
    let filecnt = document.getElementById("AttachFile") as HTMLInputElement;
    filecnt.value = "";
    this.getBoardWiseAllActiveUser();
    this.BindFileMaster();
  }
  //#endregion

  //#region View Attached Document
  ShowInOutImg(inoutid){
    this.ngxService.start();
    const url='api/'+ this._userService.get_InwardOutWard_controlerName(this.Log_BoardID)+'/BindInOutDt/'+ inoutid+'/0';
    this._userService.get_Data(url).subscribe((data)=>{
      window.open(this.globals.BaseURL+'/PimMatha_InWardOutWardImages/'+ data[0].scanImg,null);
    });
    this.ngxService.stop();
  }
  //#endregion

  //#region Forward Record
  ForwardRec(){
    if (this.ActionTaken.trim() == '') {
      alert('Please Enter Action Taken By');
      return
    }

    if (this.SendByUserID == 0) {
      alert('Please Select Send By');
      return
    }

    if (this.forwardUserID == 0) {
      alert('Please Select Forward to');
      return
    }

    if (confirm("Are you sure! You want to Forward Current InWard OutWard ?")){
      let InOutAction: any = {
        InOutActionID: this.InOutActionID,
        InOutID: this.InOutWardID,
        ConcernID: this.forwardUserID,
        ActionTaken: this.ActionTaken,
        SendByID: this.SendByUserID,
        SendDate: formatDate(this.SendDate, "dd/MM/yyyy", "en-us"),
        Remark: this.forwardRemark,
        Status: "Active"
      };

      const url = 'api/' + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/InsertUpdateInOutAction';
      this._userService.saveFormData(InOutAction, url).subscribe((data) => {
        if (data == "Forward Sucessfully.") {
          alert(data);
          if (this.PreviousDashBoard==""){
            this.clear_form()
          } else {
            this.router.navigate([this.PreviousDashBoard]);
          }
        }
        // setTimeout(() => {
        //   alert(data);
        // }, 500);
        this.ngxService.stop()
      });
    } 
  }
  //#endregion

  //#region Close Record
  CloseRec(){
    if (this.ActionTaken.trim() == '') {
      alert('Please Enter Action Taken By');
      return
    }

    if (this.SendByUserID == 0) {
      alert('Please Select Send By');
      return
    }

    if (confirm("Are you sure! You want to Close Current InWard OutWard ?")){
      let InOutAction: any = {
        InOutActionID: 0,
        InOutID: this.InOutWardID,
        ConcernID: 0,
        ActionTaken: this.ActionTaken,
        SendByID: this.SendByUserID,
        SendDate: formatDate(this.SendDate, "dd/MM/yyyy", "en-us"),
        Remark: this.forwardRemark,
        Status: "Close"
      };

      const url = 'api/' + this._userService.get_InwardOutWard_controlerName(this.Log_BoardID) + '/InsertUpdateInOutAction';
      this._userService.saveFormData(InOutAction, url).subscribe((data) => {
        if (data == "Forward Sucessfully.") {
          alert("InWard OutWard Case Close Sucessfully");
          if (this.PreviousDashBoard==""){
            this.clear_form()
          } else {
            this.router.navigate([this.PreviousDashBoard]);
          }
        }
        // setTimeout(() => {
        //   alert(data);
        // }, 500);
        this.ngxService.stop()
      });
    }
  }
  //#endregion

  //#region Cancel
  Cancel(){
    this.clear_form();
    this.router.navigate([this.PreviousDashBoard]);
  }
  //#endregion

  //#region Search Inward Action
    SearchInOutAction(){
      const url="api/"+this._userService.get_InwardOutWard_controlerName(this.Log_BoardID)+'/InOutActionSearch/'+ this.SearchInOutID;
      this._userService.get_Data(url).subscribe((data)=>{
        //console.log('searchRec',data);
        if (data.length==1){
          this.InOutWardID=this.SearchInOutID;
          this.Bind_InOutMaster();
          this.forwardUserID=data[0].concernID;
          this.ActionTaken=data[0].actionTaken;
          this.forwardRemark=data[0].remark;
          this.InOutActionID=data[0].inOutActionID;
        }else {
          if (Number(data[0].sendByID)==Number(this.Log_UserID)){
            alert('Action alredy Taken.you cannot Modify Current Record');
          } else {
            let recindex:number=0;
            for(let i=0;i<data.length;i++){
              if (Number(data[i].sendByID)==Number(this.Log_UserID)){
                recindex=i;
                break;
              }
            }
            if (recindex==data.length-1){
              this.InOutWardID=this.SearchInOutID;
              this.recFrom='DashBoard'
              this.Bind_InOutMaster();
              this.forwardUserID=data[0].concernID;
              this.ActionTaken=data[0].actionTaken;
              this.forwardRemark=data[0].remark;
              this.InOutActionID=data[0].inOutActionID;
            }else {
              alert('Action alredy Taken.you cannot Modify Current Record');
            }
          }
          
        } 
      });
    }
  //#endregion

  //#region show hide loading
  showloading_Modal: boolean;
  show_loadingModal(showhide: boolean) {
    this.showloading_Modal = showhide;
  }
  //#endregion

  //#region validate date
  invalid_DocDate = '';
  invalid_inOutDate = '';
  invalid_SendDate = '';
   vlaidateDate(event,fldName) {

   this.set_invalid_date_Flag(fldName,'invalid');

    let chkDt = new Date(event.target.value);
    // 
    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
      this.set_invalid_date_Flag(fldName,'invalid');
    }
    else {
      if (chkDt.getFullYear()) {
        this.set_invalid_date_Flag(fldName,'');
      }
     
    }
  }
  set_invalid_date_Flag(fldName,flag){
    if (fldName == 'DocDt'){
      this.invalid_DocDate = flag;

      let today_Date = new Date().toISOString().substring(0, 10);
        if (this.DocDt > today_Date) {
          this.invalid_DocDate = 'invalid';
         
        }
    }
    else if (fldName == 'inoutDate'){
      this.invalid_inOutDate = flag;
    }
    else if (fldName == 'SendDate'){
      this.invalid_SendDate = flag;
    }
  }
  setDate(event,fldName) {
    // this.recDate = event.target.value;

    let chkDt = new Date(event.target.value);
    //

    if (Number(chkDt.getFullYear()) < 1980 || Number(chkDt.getFullYear()) > 2099) {
    }
    else {
      if (fldName == 'DocDt'){
        this.DocDt = new Date(chkDt).toISOString().substring(0, 10);
        let today_Date = new Date().toISOString().substring(0, 10);

        //console.log(this.DocDt);
       // console.log(today_Date);

        if (this.DocDt > today_Date) {
          this.set_invalid_date_Flag(fldName,'invalid');
          return;
        }
        else{
          this.set_invalid_date_Flag(fldName,'');
          return;
        }

      }
      else if (fldName == 'inoutDate'){
        this.inoutDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      else if (fldName == 'SendDate'){
        this.SendDate = new Date(chkDt).toISOString().substring(0, 10);
      }
      
      
    }

  }
  //#endregion

  // #region keyPress event only numbers
 onlyNumberKey(event) {
  return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
}
onlyDecimalNumberKey(event) {
  let charCode = (event.which) ? event.which : event.keyCode;
  if (charCode != 46 && charCode > 31
    && (charCode < 48 || charCode > 57))
    return false;
  return true;
}
// #endregion

}
