import { Component } from '@angular/core';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Globals } from './Globals'
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { UserService } from './service/UserService';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  //title = 'Mathadi And Unprotected Labour Board';
  constructor(
    config: NgbAccordionConfig,
    private _userService: UserService,
    public router: Router, private titleService: Title,
    private globals: Globals,) {
      config.closeOthers = false;
    config.type = 'info';
    }

  ngOnInit() {
    if (this.globals.BaseURL == 'https://webapi.pcmcmathadi.in:9443/'){
      this.titleService.setTitle(this.globals.boardName);
      //
    }
    else{
      this.titleService.setTitle('Testing Application');
    }

    this.get_LabourDetails();
    
  
  }
  goto_Page(pgName:string){
    if (pgName == 'lab'){
      this.router.navigate(['reg-lab-nskmatha']);
    }
    else if (pgName == 'est'){
      this.router.navigate(['reg-est-nskmatha']);
    }
    
    
  }

  //#region fill labDetails
      labourData:any=[];
      newApplication;
      newResigstration;
      resigned;
      death;
      cancellation;
      newNonRefApplication;
      public get_LabourDetails() {
        //this.show_loadingModal(true);
        this.labourData = [];
        this.newApplication = '';
        this.newResigstration = '';
        this.resigned = '';
        this.death = '';
        this.cancellation = '';
        this.newNonRefApplication = '';
        let boardID: Number = 0;
  
  
        //alert(this.Applstatus);
        let url: string = 'getLabourDetailsCount/6'// + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
        console.log(url);
        this._userService.get_Dashborad_Data(url).subscribe(
          (data) => {
            // console.log('LabourDetails ',data)
            this.labourData = data;
            //this.labourData = data.clsLabourMonthly;
  
            this.newApplication = this.labourData[0]['newApplication'];
            this.newResigstration = this.labourData[0]['newResigstration'];
            this.newNonRefApplication = this.labourData[0]['newNonRefApplication'];
            this.resigned = this.labourData[0]['resigned'];
            this.death = this.labourData[0]['death'];
            this.cancellation = this.labourData[0]['cancellation'];
            //this.show_loadingModal(false);
  
            this.get_EstDetails();
  
          },
          (err: HttpErrorResponse) => {
           // this.show_loadingModal(false);
            //alert('something went wrong');
            return;
          });
  
      }
  //#endregion
   

  //#region fill estDetails
   estData: any = [];
   estActiveCnt; 
   estnewApplication;
    estnewResigstration;
    preCollectionAmt;
    penaltyCollectionAmt;
    pendingCollection;
    estcancellation;
    public get_EstDetails() {
      
      this.estData = [];
      let url: string = 'getEstCount/6';// + this.filterFayear + '/' + this.filterMonth + '/' + this.filterYr + '/' + this.filterBoradID + '/' + this.Applstatus;
      //
      this.estActiveCnt = '';
      this.estnewApplication = '';
      this.estnewResigstration = '';
      this.preCollectionAmt = '';
      this.penaltyCollectionAmt = '';
      this.pendingCollection = '';
      this.estcancellation= '';
      
  
      this._userService.get_Dashborad_Data(url).subscribe(
        (data) => {
          this.estData = data;
          //console.log('this.estData',this.estData);
          this.estActiveCnt = this.estData[0]['estActiveCnt'];
          this.estcancellation = this.estData[0]['estDeActiveCnt'];
        
        },
        (err: HttpErrorResponse) => {
          //this.show_loadingModal(false);
          //alert('something went wrong');
          return;
        });
    }
  //#endregion


}
