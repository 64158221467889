
<div  class="notranslate">
<!-- class="container dashboardmain" -->

<div class="dashboardmain" style="margin-left: 30px;margin-right: 30px;">
  <div class="row">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/">Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink="/dashboard/clerk-dashboard">Dashboard</a>
        </li>
        <li class="breadcrumb-item " aria-current="page">Receipt (Regular Board Wages/Levy) </li>
      </ol>
    </nav>

  </div>

  <!-- *ngIf="rpt_print!='yes'" -->

  <div style="text-align: center;">
    <!-- <button type="button" *ngIf="docID > 0 && rpt_print=='yes'" class="btn btn-outline-primary btn-xs"
      styleSheetFile="assets/css/table.css" [useExistingCss]="true" printSectionId="print-section" ngxPrint>Download
      Pdf</button> -->

      <button type="button" *ngIf="docID > 0 && rpt_print=='yes'" class="btn btn-outline-primary btn-xs"
       (click)="genrate_Wl_Rec_rpt()"  >Download
        Pdf</button>

    &nbsp; <button type="button" *ngIf="docID > 0 && rpt_print=='yes'" (click)="hide_show_Print('')"
      class="btn btn-outline-primary btn-xs">Cancle</button>

    &nbsp; <button type="button" *ngIf="docID > 0 && rpt_print==''" (click)="hide_show_Print('yes')"
      class="btn btn-outline-primary btn-xs">View Print</button>
  </div>
  <br>

  <div id="mcontent" *ngIf="rpt_print!='yes'">


    <div *ngIf="recPaid == 'Yes'" style="text-align: center;color: red;">
      <h2 style="color: red;">Note :- This Receipt Allredy Used In Payment You Can Change Only Bank Deatils.</h2>
    </div>



    <!-- waiting modal -->

    <div class="container">

      <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
        <div class="modal-dialog" role="document">
          <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body">

              <div style="text-align: center;">

                Please wait ...
                <div class="spinner-grow text-success"></div>
                <div class="spinner-grow text-info"></div>
                <div class="spinner-grow text-warning"></div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- waiting modal -->

    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
      <ngb-panel title="Toli / Establishment Details" id="config-panel-one">
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-2">
              <label>Receipt Type </label>
              <select  class="form-control" (change)=get_filter_debitAcList(selrectype.value,toliID) id="selrectype"
                #selrectype (keyup.enter)="set_focus('txtdocdt')">
                <option *ngFor="let rec of recTypeList" [value]="rec.typeVal" [selected]="rec.typeVal ===filterrectype">
                  {{rec.rectype}}</option>
                <!-- <option [value]="1" [selected]="filterrectype">Cash</option> -->
              </select>
            </div>
            <div class="col-md-3">
              <label for="Financial-Year">Date <span style="font-size: small;">Formation (DD/MM/YYYY)</span>
                <!-- <span style="font-size: small;color:red">*</span> -->
              </label>

              <input type="date" autofocus class="form-control" (input)="vlaidateDate($event)" (change)="setDate($event)"
                value="{{recDate}}" [disabled]='recPaidCnt_disabled' #txtdocdt (keyup.enter)="set_focus('txtwgyr')" />
              <span *ngIf="invalidDate!=''" style="font-size: small;color:red">Invalid Date</span>
            </div>

            <div class="col-md-2">
              <label for="Financial-Year">Receipt No </label>
              <input type="text" class="form-control" (keypress)="onlyNumberKey($event)" [(ngModel)]="receiptNO"
                value="{{receiptNO}}" disabled />

            </div>
            <div class="col-md-2">
              <label for="Financial-Year">Wage Year </label>
              <input type="text" (keypress)="onlyNumberKey($event)" (input)="vlaidateYear($event)" class="form-control"
                [(ngModel)]="filterYr" value="{{filterYr}}" maxlength="4" [disabled]='recPaidCnt_disabled' #txtwgyr (keyup.enter)="set_focus('txtwgmonth')" />
              <span *ngIf="invalidYear!=''" style="font-size: small;color:red">Invalid Year</span>
            </div>
            <div class="col-md-2">
              <label for="month-year">Wage Month </label>
              <input type="text" (keypress)="onlyNumberKey($event)" (input)="vlaidateMonth($event)" class="form-control"
                [(ngModel)]="filterMonth"  value="{{filterMonth}}" maxlength="2" [disabled]='recPaidCnt_disabled' #txtwgmonth (keyup.enter)="set_focus('txttolino')" />
              <span *ngIf="invalidMonth!=''" style="font-size: small;color:red">Invalid Month</span>
            </div>

          </div>


          <div class="row" style="margin-top: 15px;">



            <div class="col-md-2">
              <div class="content" role="main">

                <div class="card">
                  <div style="color: blue;">

                    <input type="text" #txttolino (keypress)="onlyNumberKey($event)"  (keyup.Enter)="get_toli_byToliNo($event)"
                      class="form-control" [disabled]='recPaidCnt_disabled' placeholder="Enter Toli No." />
                    <!-- Toli Info : {{toliInfo}} -->
                    <!-- <a *ngIf="recPaid == 'No'" href="javascript:void(0)"><i class="fa fa-edit" ngbTooltip="Edit"
                        aria-hidden="true" (click)="show_serchtoli_Cnt()"></i></a> -->
                  </div>

                </div>

              </div>

            </div>

            <div class="col-md-10">
              <div class="content" role="main">

                <div class="card">
                  <div class="form-group" *ngIf="toliserchCnt" style="display: none;">
                    <div class="form-group has-search">
                      <i class="fa fa-search form-control-feedback"></i>
                      <input type="text" class="form-control" id="search-toli" aria-describedby="search-toli"
                        [(ngModel)]="searchToli" (input)="serch_toli()" placeholder="Enter text to search Toli"
                        autofocus>

                      <div>
                        <ul class="list-group list-group-flush anyClass">
                          <li class="list-group-item nav-item" *ngFor="let rec of filter_toli" appHighlight
                            [searchedWord]="searchToli" [content]="rec.mukName" [classToApply]="'font-weight-bold'"
                            [setTitle]="'true'" (click)="set_searchToli(rec.toliId,rec.toliNo,rec.mukName,rec.levy);"
                            style="background-color: #dcf9f6;">
                            {{rec.toliNo}} - {{rec.mukName}}
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>


                  <div style="color: blue;">
                    Toli Info :- {{toliInfo}}
                    
                  </div>

                </div>

              </div>

            </div>
          </div>


          <div style="display: none;">

          <div class="row" style="margin-top: 5px;">
            <div class="col-md-5">
              <!-- <div class="content" role="main">

                <div class="card">

                  <div class="form-group" *ngIf="subestserchCnt">
                    <div class="form-group has-search">
                      <i class="fa fa-search form-control-feedback"></i>
                      <input type="text" class="form-control" id="search-subest" aria-describedby="search-subest"
                        [(ngModel)]="searchsubEst" (input)="serch_subest(0)"
                        placeholder="Enter text to search Sub-Establishment" autofocus>

                      <ul class="list-group list-group-flush anyClass">
                        <li class="list-group-item nav-item" *ngFor="let rec of filter_subest" appHighlight
                          [searchedWord]="searchsubEst" [content]="rec.filterval" [classToApply]="'font-weight-bold'"
                          [setTitle]="'true'"
                          (click)="set_searchsubEst_Cnt(rec.ourSubEstID,rec.subEstID,rec.filterval);"
                          style="background-color: #dcf9f6;">
                          {{rec.filterval}}
                        </li>
                      </ul>

                    </div>

                  </div>


                  <div style="color: blue;">Sub-Establishment Info : {{subestInfo}}
                    <a href="javascript:void(0)" *ngIf="filter_subest.length > 1"><i class="fa fa-edit"
                        ngbTooltip="Edit" aria-hidden="true" (click)="show_serchsubest_Cnt()"></i></a>
                  </div>


                </div>

              </div> -->
            </div>

            <!-- <div class="col-md-7">
              <div class="content" role="main">
                <div class="card">
                  <div style="color: blue;">New Sub-Estblishment Name :
                    <input type="text" class="form-control" [(ngModel)]="newsubEstName" value="{{newsubEstName}}" />
                  </div>
                </div>
              </div>
            </div> -->
          </div>

          <div class="row" style="margin-top: 5px;">
            <div class="col-md-5">
              <div class="content" role="main">

                <div class="card">
                  <div class="form-group" *ngIf="marserchCnt">
                    <div class="form-group has-search">
                      <i class="fa fa-search form-control-feedback"></i>
                      <input type="text" class="form-control" id="search-mar" aria-describedby="search-mar"
                        [(ngModel)]="searchMar" (input)="serch_mar(0)" placeholder="Enter text to search Sub-Division"
                        autofocus>

                      <ul class="llist-group list-group-flush anyClass">
                        <li class="list-group-item nav-item" *ngFor="let rec of filter_marcom" appHighlight
                          [searchedWord]="searchMar" [content]="rec.filterval" [classToApply]="'font-weight-bold'"
                          [setTitle]="'true'"
                          (click)="set_searchMar_Cnt(rec.marketID,rec.marketCode,rec.filterval,rec.companyID,rec.companyname);"
                          style="background-color: #dcf9f6;">
                          {{rec.filterval}}
                        </li>
                      </ul>
                    </div>


                  </div>


                  <div style="color: blue;">Sub-Division Info : {{marInfo}}
                    <a href="javascript:void(0)" *ngIf="filter_marcom.length > 1"><i class="fa fa-edit"
                        ngbTooltip="Edit" aria-hidden="true" (click)="show_serchmar_Cnt()"></i></a>
                  </div>

                </div>

              </div>
            </div>

            <div class="col-md-7">
              <div class="content" role="main">
                <div class="card">
                  <div style="color: blue;">Division Info : {{compInfo}}
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        </ng-template>
      </ngb-panel>

      <!-- *ngIf="filterrectype == 0" -->

      <ngb-panel  title="Cheque Details" id="config-panel-one" >
        <ng-template ngbPanelContent>
          <form [formGroup]="frmchq">

            <div class="row">
              
              <div class="col-md-4">
                <div class="font-15 text-center" style="text-align: center;margin-left: 30px;margin-right: 30px;"> Do
                  You
                  Want To Add New Sub-Est. ?
                  <a href="javascript:void(0)" style="font-weight: bold;" (click)="show_subest_Modal()">Cleck here</a>
                </div>
              </div>
              
              <div class="col-md-6">
                <div class="font-15 text-center" style="text-align: center;margin-left: 30px;margin-right: 30px;"> Do
                  You
                  Want To Add New IFSC Code ?
                  <a href="javascript:void(0)" style="font-weight: bold;" (click)="show()">Cleck here</a>
                </div>
              </div>

              <div class="col-md-2">
                <button type="button" (click)="get_estUpAmt()" class="btn btn-outline-primary btn-xs">Get UPI Amt.</button>
              </div>
            </div>


            <div class="row ex3">
              <table class="table table-hover table-responsive-xl labour-table">
                <thead>
                  <th>Est. Name</th>
                  <th >Sub-Est. Name </th>
                  <th>Deposit Type </th>
                  <th>IFSC Code </th>
                  <th style="display: none;">City </th>
                  <th>Bank </th>
                  <th>Branch </th>
                  <th>Chq./UTR. No.</th>
                  <th>Chq. Date</th>
                  <th>Amount</th>
                  <th></th>
                  <th></th>
                  <th></th>

                </thead>
                <tbody>

                  <ng-container>

                    <tr class="sub-tr">
                      <td>
                        <div class="content" role="main">

                          <div class="card">
                            <!--  -->
                            <div  *ngIf="estserchCnt"> 
                              <div class="form-group has-search">
                                <i class="fa fa-search form-control-feedback"></i>
                                <input type="text" class="form-control" id="search-est" aria-describedby="search-est"
                                #txtsearchest  (input)="serch_est(0,$event)" (keydown.Enter)="get_est_byEstNo($event)"
                                  placeholder="Enter text to search Establishment" autofocus>
          
                                <ul *ngIf="searchEst"  class="list-group list-group-flush anyClass">
                                  <li class="list-group-item nav-item" id="liList" *ngFor="let rec of filter_est" appHighlight
                                    [searchedWord]="searchEst" [content]="rec.filterval" [classToApply]="'font-weight-bold'"
                                    [setTitle]="'true'" (click)="set_searchEst_Cnt(rec.estID,rec.estRegNo,rec.filterval);"
                                    style="background-color: #dcf9f6;">
                                    {{rec.filterval}}
                                  </li>
                                </ul>
          
                              </div>
          
                            </div>
          
          
                            <div *ngIf="estInfo" style="color: blue;">{{estInfo}}
                              <a href="javascript:void(0)"><i class="fa fa-edit" ngbTooltip="Edit" aria-hidden="true"
                                  (click)="show_serchest_Cnt()"></i></a>
                           
                            </div>
          
                          </div>
          
                        </div>
                      </td>
                      <td>
                        <div class="content" role="main">

                          <div class="card"  >
          
                            <div class="form-group" *ngIf="subestserchCnt">
                              <div class="form-group has-search">
                                <i class="fa fa-search form-control-feedback"></i>
                                <input type="text" class="form-control" id="search-subest" aria-describedby="search-subest"
                                  [(ngModel)]="searchsubEst" (input)="serch_subest(0,$event)"
                                  placeholder="Enter text to search Sub-Establishment" autofocus>
          
                                <ul  class="list-group list-group-flush anyClass">
                                  <li class="list-group-item nav-item" id="subliList" *ngFor="let rec of filter_subest" appHighlight
                                    [searchedWord]="searchsubEst" [content]="rec.filterval" [classToApply]="'font-weight-bold'"
                                    [setTitle]="'true'"
                                    (click)="set_searchsubEst_Cnt(rec.estID,rec.estRegNo,rec.filterval);"
                                    style="background-color: #dcf9f6;">
                                    {{rec.filterval}}
                                  </li>
                                </ul>
          
                              </div>
          
                            </div>
          
          
                            <div style="color: blue;" *ngIf="subestInfo"> {{subestInfo}}
                              <a href="javascript:void(0)"><i class="fa fa-edit"
                                  ngbTooltip="Edit" aria-hidden="true" (click)="show_serchsubest_Cnt()"></i></a>
                            </div>
          
          
                          </div>

                          <!-- <div class="content" role="main">
                            <div class="card">
                              <div >
                                <input type="text" class="form-control" formControlName="txtnewsubEstName" #txtnewsubEstName id="txtnewsubEstName" [(ngModel)]="newsubEstName" value="{{newsubEstName}}" />
                              </div>
                            </div>
                          </div> -->
          
                        </div>
                      <!-- </td>(keyup.enter)="set_focus('txtifsccode')" -->
                      <td> <select style="width: 100px" class="form-control" #selchqtype formControlName="selchqtype"
                          id="selchqtype" (keydown)="set_focus_1($event,'txtifsccode')" >
                          <option value=''></option>
                          <option value='Direct Deposit'>Direct Deposit</option>
                          <option value='RTGS'>RTGS</option>
                          <option value='Transfer'>Transfer</option>
                          <option value='NEFT'>NEFT</option>
                          <option value='IMPS'>IMPS</option>
                        </select></td>
                      <td>
                        <input type="text" style="width: 150px" class="form-control" formControlName="txtifsccode"
                          id="txtifsccode" #txtifsccode [(ngModel)]="searchIfsc" (input)="serch_ifsc()" (keydown.Enter)="set_NA_ChqBank($event);set_focus('txtchqno')" value="{{searchIfsc}}"
                          maxlength="11" />

                        <ul class="list-group list-group-flush anyClass" *ngIf="filter_chqbank.length >0">
                          <li class="list-group-item nav-item" *ngFor="let rec of filter_chqbank" appHighlight
                            [searchedWord]="searchIfsc" [content]="rec.ifscCode" [classToApply]="'font-weight-bold'"
                            [setTitle]="'true'"
                            (click)="set_searchChqBank(rec.ifscCode,rec.branchID,rec.estBankID,rec.estBankName,rec.branchName,rec.city);"
                            style="background-color: #dcf9f6;">
                            {{rec.ifscCode}} - {{rec.estBankName}} - {{rec.branchName}}
                          </li>
                        </ul>


                      </td>
                      <td style="display: none;"> {{txtcity}} </td>
                      <td>{{txtbankname}}</td>
                      <td>{{txtbranchname}} </td>
                      <td><input type="text" style="width: 100px" class="form-control" formControlName="txtchqno"
                          #txtchqno id="txtchqno" (keydown.enter)="set_focus('txtchqdate')" /></td>

                      <td><input type="date" style="width: 170px" class="form-control" formControlName="txtchqdate"
                        (input)="validate_ChqDate($event)" (change)="set_ChqDate($event)"
                          #txtchqdate id="txtchqdate" (keydown.enter)="set_focus('txtchqamt')" value="{{chqdate}}" />

                          <span *ngIf="invalid_ChqDate!=''" style="font-size: small;color:red">Invalid Date</span>

                        <!-- <input type="date" class="form-control" id="BirthDate" name="BirthDate"
                        [ngModel]="BirthDate | date:'yyyy-MM-dd'"  formControlName="BirthDate"> -->

                      </td>
                      <td><input type="text" (keypress)="onlyDecimalNumberKey($event)"
                          style="width: 100px;text-align: end" class="form-control" formControlName="txtchqamt"
                          #txtchqamt id="txtchqamt" (keydown.enter)="set_focus('btnaddchq')" /> </td>
                      <td> <button type="button" class="btn btn-outline-primary btn-xs" #btnaddchq
                          (click)="add_chqDt('',txtchqno.value,txtchqdate.value,txtchqamt.value,'')">{{chqAddUpdate}}</button>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr *ngFor='let rec of bind_ChqList;let i = index' class="sub-tr">
                      <td style="text-align: left">{{rec.estName}}</td>
                      <td style="text-align: left">{{rec.subEstName}}</td>
                      <td style="text-align: left">{{rec.depositType}} </td>
                      <td style="text-align: left">{{rec.ifscCode}}</td>
                      <td style="text-align: left;display: none;">{{rec.city}}</td>
                      <td style="text-align: left">{{rec.bankName}}</td>
                      <td style="text-align: left">{{rec.branchName}} </td>
                      <td style="text-align: left">{{rec.chqno}}</td>
                      <td style="text-align: left">{{rec.bindchqdate | date :'dd/MM/yyyy'}}</td>
                      <td style="text-align: right;">{{rec.chqamt}}</td>
                      <td> <button type="button" class="btn btn-outline-primary btn-xs"
                          (click)="set_chqEditVal(i,rec.ifscCode,rec.chqno,rec.chqdate,rec.chqamt,rec.depositType,rec.subEstName)">Edit</button>
                      </td>
                      <td><button type="button" class="btn btn-outline-danger btn-xs"
                          (click)="del_chq(i)">Delete</button></td>
                      <td></td>
                    </tr>

                  </ng-container>
                </tbody>
                <tfoot>
                  <tr Class="table-footer">
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td style="display: none;"> </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align: right;">Total :</td>
                    <td style="text-align: right;"> {{tot_ChqAmt}} </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>


            </div>
          </form>

        </ng-template>
      </ngb-panel>

      <ngb-panel title="Wages/Levy Details" id="config-panel-one">
        <ng-template ngbPanelContent>
          <div style="text-align: center;">
            <h2>Note :- Levy will be Calculated According to {{tot_LevyPer}} % And Anamat {{tot_AnamatPar}} %</h2>
          </div>

          <div class="row ex3">
            <table class="table table-hover table-responsive-xl labour-table">
              <thead>
                <th>A/C Name </th>
                <th style="text-align: start">Amount </th>
                <th></th>
              </thead>
              <tbody>

                <ng-container>
                  <tr *ngFor='let rec of transDtRec;let i = index' class="sub-tr">
                    <td> {{rec.crAccName}}</td>
                    <td > <input type="text" id="{{'txtcramt'+i}}"  style="width: fit-content;text-align: start" class="form-control"
                       (keypress)="onlyDecimalNumberKey($event)" (keydown)="cal_levy($event,rec.acctype)"
                        (change)="set_Cr_AmtChange($event,i)" value="{{rec.cramt}}" readonly="{{rec.disabled}}"
                        (keydown)="OnKeyDown(i,$event)"  [disabled]='recPaidCnt_disabled' />
                      <!--  -->
                    </td>
                    <td></td>
                  </tr>
                </ng-container>
              </tbody>

              <tfoot>
                <tr Class="table-footer">
                  <td>Total :</td>
                  <td><input type="text" readonly="true" style="width: fit-content;text-align: start"
                      class="form-control" value="{{transAmt}}" /></td>
                  <!-- value="{{transAmt}}" -->
                  <td></td>
                </tr>
              </tfoot>
            </table>


          </div>



        </ng-template>
      </ngb-panel>
    </ngb-accordion>


    <div class="row">
      <div class="col-md-2" >
        <label for="Financial-Year">Advance Amt. </label>
        <input type="text" #txtadvamt (keypress)="onlyDecimalNumberKey($event)" [(ngModel)]="lessAdvance"
          (change)="set_wages_after_LessAdv($event)" class="form-control" value="{{lessAdvance}}"
          (keydown.enter)="set_focus('seldbac')"  [disabled]='recPaidCnt_disabled' />
      </div>

      <!-- (keydown.enter)="set_focus('btnsubmit')" -->
      <div class="col-md-4">

        <label for="Financial-Year">Select Deposit A/C </label>
        <select class="form-control" #seldbac (change)="set_debitAc($event)" id="seldbac">
          <option *ngFor='let rec of filter_debitAcList;let i = index' value="{{rec.accID}}"
          (keydown)="set_focus_1($event,'btnsubmit')" [selected]="rec.accID===filterprinAccID">{{rec.accName}}</option>
        </select>
      </div>

      <div class="col-md-6" *ngIf="show_trdbAc!=''">

        <label for="Financial-Year">Transfer To Bank Name </label>
        <select class="form-control" #seltrdbac (change)="set_debit_TrandferAc($event)" id="seltrdbac" >
          <option *ngFor='let rec of filter_debitTrAcList;let i = index' value="{{rec.accID}}"
            [selected]="rec.accID===filterTrAccID">{{rec.accName}}</option>
        </select>
      </div>


     
      <!-- <div class="col-md-2">
          <label for="Financial-Year">Less Amt </label>
          <input type="text" class="form-control" value="{{lessTot}}" disabled/>
        </div>
        <div class="col-md-2">
          <label for="Financial-Year">Other Amt </label>
          <input type="text" class="form-control" value="{{otherTot}}" disabled/>
    
        </div>
     -->
    </div>

    <br>
    <div class="text-center">
      &nbsp; <button type="button" #btnsubmit id ="btnsubmit" class="btn btn-primary" (click)="insert_update(seldbac.value);"
        [disabled]="rec_submit">Submit</button>

      &nbsp; <button type="button" (click)="cancle_entry()" class="btn btn-outline-primary btn-xs">Cancle</button>

      &nbsp; <button *ngIf="docID > 0 && recPaid == 'No'" type="button" class="btn btn-danger"
        (click)="delte_entry()">Delete</button>




    </div>

  </div>

</div>

<!-- Add New Bank Branch Details Modal -->
<div class="container">

  <div class="modal" id="myModal" [style.display]="showModal ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header" style="background-color: #1A5276;color: white;">
          <h4 class="modal-title">New Bank Details</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="hide()"
            style="color: white;">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form [formGroup]="frm_newChqBank" (ngSubmit)="onSubmit_newChqBank()">
            <div class="form-group">
              <label for="bank">Bank Name:</label>
              <input type="text" class="form-control" placeholder="Enter bank name" id="nbank" formControlName="nbank"
                [ngClass]="{ 'is-invalid': submitted && f.nbank.errors }" required>
              <div *ngIf="submitted && f.nbank.errors" class="invalid-feedback">
                <div *ngIf="f.nbank.errors.required">Bank Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <label for="branch">Branch Name:</label>
              <input type="text" class="form-control" placeholder="Enter branch name" id="nbranch"
                formControlName="nbranch" [ngClass]="{ 'is-invalid': submitted && f.nbranch.errors }" required>
              <div *ngIf="submitted && f.nbranch.errors" class="invalid-feedback">
                <div *ngIf="f.nbranch.errors.required">Branch Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <label for="city">City Name:</label>
              <input type="text" class="form-control" placeholder="Enter city name" id="ncity" formControlName="ncity"
                [ngClass]="{ 'is-invalid': submitted && f.ncity.errors }" required>
              <div *ngIf="submitted && f.ncity.errors" class="invalid-feedback">
                <div *ngIf="f.ncity.errors.required">City Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <label for="ifsc">IFSC Code:</label>
              <input type="text" style="text-transform: uppercase;" class="form-control" placeholder="Enter ifsc code"
                id="nifsccode" maxlength="11" formControlName="nifsccode"
                [ngClass]="{ 'is-invalid': submitted && f.nifsccode.errors }" required>
              <div *ngIf="submitted && f.nifsccode.errors" class="invalid-feedback">
                <div *ngIf="f.nifsccode.errors.required">IFSC Code is required</div>
                <div *ngIf="f.nifsccode.errors.minlength">IFSC Code must be at least 11 characters</div>

              </div>

            </div>

            <div class="form-group">
              <button class="btn btn-primary">Save</button>
            </div>
          </form>


          <!-- Modal footer -->
          <!-- <div class="modal-footer" style="background-color: #1A5276;color: white;">
  
            <button type="submit" class="btn btn-outline-primary" style="color: white;" data-dismiss="modal" (click)="hide()">Save</button>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Add New Sub-Est -->
<div class="container">

  <div class="modal" id="suestModal" [style.display]="showModal_subest ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header" style="background-color: #1A5276;color: white;">
          <h4 class="modal-title">New Sub-Est. Details</h4>
          <button type="button" class="close" data-dismiss="modal" (click)="hide_subest_Modal()"
            style="color: white;">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form [formGroup]="frm_newsubEst" (ngSubmit)="onSubmit_subest_Modal()">
            <div class="form-group">
              <label for="subest">Sub-Est. Name:</label>
              <input type="text" class="form-control" placeholder="Enter sub-est name" id="new_subEstName" formControlName="new_subEstName"
                [ngClass]="{ 'is-invalid': submitted && f_subest.new_subEstName.errors }" required>
              <div *ngIf="submitted && f_subest.new_subEstName.errors" class="invalid-feedback">
                <div *ngIf="f_subest.new_subEstName.errors.required">Sub-Est Name is required</div>
              </div>
            </div>

            <div class="form-group">
              <button class="btn btn-primary">Save</button>
            </div>
          </form>


          <!-- Modal footer -->
          <!-- <div class="modal-footer" style="background-color: #1A5276;color: white;">
  
            <button type="submit" class="btn btn-outline-primary" style="color: white;" data-dismiss="modal" (click)="hide()">Save</button>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" *ngIf="docID > 0 && rpt_print=='yes'">
  <div class="container dashboardmain">
    <div id="print-section" style="text-align: center;">
      <table class="table table-bordered" style="height: 400px;">
        <tbody>
          <tr>
            <td style="text-align: center;" colspan="2">
              <h3 style="font-weight: bold;">पिंपरी चिंचवड माथाडी व असंरक्षित कामगार मंडळ</h3>

              <p></p>
              <h3 style="font-weight: bold;">पावती</h3>


          </tr>

          <!-- <tr >
        <td style="text-align: center;" colspan="2"><h3 style="font-weight: bold;"><p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 , PhNo.0253-2501350</p></h3> 
        
        <td></td>
        
      </tr> -->

          <!-- <tr >
        <td style="border-left: 1px solid white;text-align: center;" colspan="2"><p>Ratna Manohar Sankul ,2nd Floor, Opst. Sunder Narayan Mandir Ravivar Peth Nashik-1 422 001 , PhNo.0253-2501350</p> </td>
        
      </tr> -->

          <tr>
            <td style="text-align: left;">
              <span style="font-weight: bold;">पावती क्र. : </span>{{receiptNO}}
            </td>
            <td colspan="2" style="text-align: left;">
              <span style="font-weight: bold;">पावती दिनांक : </span>{{recDate | date :'dd/MM/yyyy'}}
            </td>
          </tr>
          <tr>

            <td style="text-align: left;border-bottom-color: white;" colspan="2">
              <span style="font-weight: bold;">टोळी क्र. : </span>{{toliInfo}}
            </td>
          </tr>
          <tr>
            <td style="text-align: left;" colspan="2">
              <div *ngIf="subestName ==''">
                <span style="font-weight: bold;">मे./श्री. : </span> {{estInfo}}
              </div>
              <div *ngIf="subestName !=''">
                <span style="font-weight: bold;">मे./श्री. : </span> {{subestInfo}}
              </div>



          </tr>
          <tr>
            <td style="text-align: left;" colspan="2">
              <span style="font-weight: bold;">यांचेकडुन माहे</span> {{filterYr}} / {{filterMonth}}
          </tr>
          <!-- <tr>
            <td style="text-align: left;">धनादेश क्र.</td>
            <td style="text-align: left;">{{rpt_chqNo}}</td>
          </tr> -->
          <tr>
            <td style="text-align: left;">मजुरी रक्कम</td>
            <td style="text-align: right;">{{rpt_Wages}}</td>
          </tr>
          <tr>
            <td style="text-align: left;">लेव्ही रक्कम</td>
            <td style="text-align: right;">{{rpt_Levy}}</td>
          </tr>
          <tr>
            <td style="text-align: left;">अनामत रक्कम</td>
            <td style="text-align: right;">{{rpt_anamat}}</td>
          </tr>
          <tr>
            <td style="text-align: left;font-weight: bold;">एकुण रक्कम</td>
            <td style="text-align: right;font-weight: bold;">{{transAmt}} </td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: left;">जमा खाते : {{rpt_debitAc}} </td>

          </tr>
          <tr>
            <td colspan="2" style="text-align: left;">उचल कपात  : {{lessAdvance}}</td>

          </tr>
         

         
         
        </tbody>
      </table>
     
      <table class="table table-bordered">
        <tbody>
          <tr>
            <!-- <th>
              आ.नोंदणी क्रं
            </th> -->
            <th>
              अस्थापनेचे नाव 
            </th>
            <th>
              धनादेश बँकेचे नाव 
            </th>
            <th>
              जमा प्रकार
            </th>
            <th>
              धनादेश क्र.
            </th>
            <th>
              धनादेश दि.
            </th>
            <th>
              धनादेश  रक्कम
            </th>
           
          </tr>
          <tr *ngFor='let rec of bind_ChqList;let i = index'>
            <!-- <td></td> -->
            <td>{{rec.estName}}</td>
            <td>{{rec.bankName}}</td>
            <td>{{rec.depositType}}</td>
            <td>{{rec.chqno}}</td>
            <td>{{rec.bindchqdate | date :'dd/MM/yyyy'}}</td>
            <td>{{rec.chqamt}}</td>
          </tr>
          <tr>
            

            <!-- <td></td> -->
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style="font-weight: bold;">एकुण रक्कम</td>
            <td style="font-weight: bold;">{{tot_ChqAmt}}</td>
          </tr>
          <tr >
            <td style="font-weight: bold;" colspan="6" style="text-align: right;border-top-color: white;">स्वीकारणाऱ्याची सही</td>
          </tr>
        </tbody>
      </table>

    </div>

    <!-- <div *ngIf="docID > 0 && rpt_print=='yes'" style="text-align: center;margin-top: 5%;">
      <button type="button" class="btn btn-outline-primary btn-xs" styleSheetFile="assets/css/table.css"
        [useExistingCss]="true" printSectionId="print-section" ngxPrint>Download Pdf</button>
    </div> -->

  </div>

</div>


</div>