import { Injectable } from '@angular/core';

@Injectable()
export class Globals {


//public BaseURL: string = 'http://localhost:52936/';

//public BaseURL: string = 'http://localhost:52936/';
 
 //public BaseURL: string = 'http://tempwebapi.pcmcmathadi.in/'; //testing

public BaseURL: string = 'https://webapi.pcmcmathadi.in:9443/';

//public boardName: string = 'Mathadi And Unprotected Labour Board';
public boardName: string = 'Pimpri Chinchwad Mathadi & Unprotected Worker Board';

 public HomeImagePath: string = 'https://machineryonrentsale.com/apimachineryrentsale/MachineImages/';
 public HomeCatImagePath: string = 'https://machineryonrentsale.com/apimachineryrentsale/MachineImages/';
 public MyAddedMchImagePath: string = 'https://machineryonrentsale.com/apimachineryrentsale/MachineImages/';
   
 public GlbMachineType: string = '';
 public log_userName: string = '';
 public is_login: boolean = false;
 

}//