<div  class="notranslate">
<div>
    <div class="container dashboardmain">
        <div class="row">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)" routerLink={{PreviousDashBoard}}>Home</a>
                    </li>
                    <li class="breadcrumb-item " aria-current="page">InWard/OutWard </li>
                </ol>
            </nav>

        </div>

    </div>

    <!-- waiting modal -->

    <div class="container">

        <div class="modal" id="loadingModal" [style.display]="showloading_Modal ? 'block' : 'none'">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <!-- Modal body -->
                    <div class="modal-body">

                        <div style="text-align: center;">

                            Please wait ...
                            <div class="spinner-grow text-success"></div>
                            <div class="spinner-grow text-info"></div>
                            <div class="spinner-grow text-warning"></div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- waiting modal -->

    <div id="mcontent" class="container dashboardmain">
        <div class="row">
            <table class="table table-hover table-responsive-sm labour-table table-striped ">
                <tbody>
                    <tr class="sub-tr">
                        <td>{{InOutFlag + ' No :'}}
                           
                        </td>
                        <td>
                            <input type="text" class="form-control" [(ngModel)]="InOutNo" (keypress)="onlyNumberKey($event)" [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />    
                        </td>
                        <td style="width: 150px;">
                            <div class="custom-control custom-radio">
                                <input id="rbtnInward" type="radio" class="custom-control-input" name="authorize"
                                    (change)="InOutSelect('InWard')" value="Inward" [checked]="isInSel"
                                    [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                <label class="custom-control-label" for="rbtnInward"
                                    style="padding-top: 2px;">InWard</label>
                            </div>
                        </td>
                        <td style="width: 180px;">
                            <div class="custom-control custom-radio">
                                <input id="rbtnOutward" type="radio" class="custom-control-input"
                                    (change)="InOutSelect('OutWard')" name="authorize" value="Outward"
                                    [checked]="!isInSel" [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                <label class="custom-control-label" style="padding-top: 2px;"
                                    for="rbtnOutward">OutWard</label>
                            </div>
                        </td>
                        <td>
                            <label for="In-Out Date" style="padding-top: 2px;">{{InOutFlag + ' Date :'}}
                            </label>


                        </td>
                        <td>
                            <!-- <input type="text" class="form-control col-md-6" value="{{inoutDate | date:'dd/MM/yyyy'}}"
                                disabled="true" bsDatepicker [bsConfig]="bsdateconfig" /> -->

                                <input type="date" class="form-control" (input)="vlaidateDate($event,'inoutDate')" (change)="setDate($event,'inoutDate')"
                                value="{{inoutDate}}" disabled="true"  />

                              <span *ngIf="invalid_inOutDate!=''" style="font-size: small;color:red">Invalid Date</span>

                        </td>
                        <td></td>
                        <td></td>

                    </tr>
                </tbody>

            </table>
        </div>

        <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
            <ngb-panel title="Search InWard Outward" id="Search">
                <ng-template ngbPanelContent>
                    <div class="row">
                        <div class="col-md-2">

                        </div>
                        <div class="col-md-2" style="padding-top: 10px;">
                            {{InOutFlag + ' No :'}}
                        </div>
                        <div class="col-md-2">
                            <select class="form-control" id="selinoutno" #selinoutno [(ngModel)]="SearchInOutID"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                <option value="0"></option>
                                <option *ngFor="let rec of SearchInOutList" [value]="rec.inOutID">{{rec.inOutNo}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <button type="button" class="form-control btn-primary"
                                (click)="SearchInOutAction()">Search</button>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="Document Information" id="config-panel-one">
                <ng-template ngbPanelContent>
                    <div class="row">

                        <div class="col-md-4">
                            <label for="Document-Date">Document Date <span style="font-size: small;">
                                    (DD/MM/YYYY)</span>
                            </label>

                            <!-- <input type="text" class="form-control" value="{{DocDt | date :'dd/MM/yyyy'}}" bsDatepicker
                                [bsConfig]="bsdateconfig" [maxDate]="maxAsOnDate"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'" readonly /> -->

                                <input type="date" class="form-control" (input)="vlaidateDate($event,'DocDt')" (change)="setDate($event,'DocDt')"
                                 [max]="maxAsOnDate" value="{{DocDt}}" [disabled]="InOutWardID>0 && recFrom=='DashBoard'"  />
                              <span *ngIf="invalid_DocDate!=''" style="font-size: small;color:red">Invalid Date</span>


                        </div>

                        <div class="col-md-8">
                            <label for="From-Whom">Address To
                            </label>

                            <input type="text" class="form-control" [(ngModel)]="AddressTo"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-4">
                            <label for="From-Whom">From Whom
                            </label>

                            <input type="text" class="form-control" [(ngModel)]="FromWhom"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                        </div>
                        <div class="col-md-8">
                            <label for="Address">Address
                            </label>

                            <input type="text" class="form-control" [(ngModel)]="InOutAddress"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <label for="Subject">Subject
                            </label>

                            <input type="text" id="Subject" class="form-control" [(ngModel)]="Subject"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-8">
                            <label for="Description">Description
                            </label>

                            <textarea rows="2" class="form-control" [(ngModel)]="description"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'"></textarea>
                        </div>
                        <div class="col-md-4">
                            <label for="Delivery-Typer">Delivery Type
                            </label>

                            <select class="form-control" id="selinoutno" #selinoutno [(ngModel)]="delivaryType"
                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                <option value=""></option>
                                <option value="By Hand">By Hand</option>
                                <option value="Courier">Courier</option>
                                <option value="Register Post">Register Post</option>
                                <option value="Email">Email</option>
                                <option value="WhatsApp">WhatsApp</option>
                            </select>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="content" role="main">
                                <div class="card">
                                    <div class="form-group" *ngIf="fileserchCnt">
                                        <div class="form-group has-search">
                                            <i class="fa fa-search form-control-feedback"></i>
                                            <input type="text" class="form-control" id="search-toli"
                                                aria-describedby="search-toli" [(ngModel)]="searchFile"
                                                placeholder="Enter text to search File" autofocus
                                                (keyup)="SearchFileByName()"
                                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'">

                                            <div>
                                                <ul class="list-group list-group-flush anyClass">
                                                    <li class="list-group-item nav-item" *ngFor="let rec of filter_file"
                                                        appHighlight style="background-color: #dcf9f6;"
                                                        (click)="setSearchFile(rec.fileID,rec.fileNo,rec.fileName,rec.flag)">
                                                        {{rec.fileNo}} - {{rec.fileName}}
                                                    </li>
                                                    <!-- [searchedWord]="searchFile" 
                                                        [content]="rec.fileName"
                                                        [setTitle]="'true'"
                                                        [classToApply]="'font-weight-bold'"
                                                    -->

                                                </ul>
                                            </div>
                                        </div>

                                    </div>


                                    <div style="color: blue;">

                                        File Info :- {{fileInfo}}
                                        <a href="javascript:void(0)"><i class="fa fa-edit" ngbTooltip="Create New File"
                                                (click)="show_modal_file()" aria-hidden="true"></i></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel title="InWard OutWard Reference" id="config-panel-one">
                <ng-template ngbPanelContent>

                    <div class="row">
                        <table class="table table-hover table-responsive-sm labour-table table-striped ">

                            <tbody>
                                <tr class="sub-tr">
                                    <td></td>

                                    <td style="width: 250px;">
                                        <div class="custom-control custom-radio">
                                            <input id="rbtnInwardRef" type="radio" class="custom-control-input"
                                                name="InOutRef" (change)="InOutRefSelect('InWard')" value="InWard"
                                                [checked]="rbtnInwardchekd"
                                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                            <label class="custom-control-label" for="rbtnInwardRef"
                                                style="padding-top: 2px;">InWard RefNo</label>
                                        </div>
                                    </td>
                                    <td style="width: 250px;">
                                        <div class="custom-control custom-radio">
                                            <input id="rbtnOutwardRef" type="radio" class="custom-control-input"
                                                (change)="InOutRefSelect('OutWard')" name="InOutRef" value="OutWard"
                                                [checked]="rbtnOutwardchekd"
                                                [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                            <label class="custom-control-label" style="padding-top: 2px;"
                                                for="rbtnOutwardRef">OutWard RefNo</label>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                    <div class="col-md-12">
                        <table class="table table-hover table-responsive-xl labour-table">
                            <thead>
                                <th></th>
                                <th>Date </th>
                                <th>Inward No</th>
                                <th>Address To</th>
                                <th>From Whom</th>
                                <th>Address</th>
                                <th>Subject</th>
                                <th></th>
                            </thead>
                            <!-- <tr *ngFor='let rec of docList;let i = index' class="sub-tr border"> -->
                            <tr class="sub-tr border">
                                <td class="col-md-1"></td>
                                <td class="col-md-2">
                                    <select class="form-control" id="InOutRefDate" [(ngModel)]="refInOutdate"
                                        (ngModelChange)="filterRefList()"
                                        [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                        <option value=""></option>
                                        <option *ngFor="let inoutdate of InOutRefDateList"
                                            [value]="inoutdate|date:'dd/MM/yyyy'">{{inoutdate|date:'dd/MM/yyyy'}}
                                        </option>
                                    </select>
                                </td>
                                <td class="col-md-1">
                                    <!-- class="form-group" -->
                                    <input type="number" id="InOutRefNo" class="form-control" min="0" (keypress)="onlyNumberKey($event)" [(ngModel)]="refInOutNo"
                                        (ngModelChange)="filterRefList()"
                                        [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                                    <!-- <select class="form-control" id="InOutRefNo"  [(ngModel)]="refInOutNo" (ngModelChange)="filterRefList()">
                                        <option value=0></option>
                                        <option *ngFor="let inoutNo of InOutRefNoList" [value]="inoutNo">{{inoutNo}}</option>
                                    </select> -->
                                </td>
                                <td class="col-md-2">
                                    <input type="text" id="InOutaddTo" class="form-control"
                                        [(ngModel)]="refInOutAddressTo" (ngModelChange)="filterRefList()"
                                        [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                                    <!-- <select class="form-control" id="InOutaddTo"  [(ngModel)]="refInOutAddressTo" (ngModelChange)="filterRefList()">
                                        <option value=""></option>
                                        <option *ngFor="let inoutaddTo of InOutRefAddressToList" [value]="inoutaddTo">{{inoutaddTo}}</option>
                                    </select> -->
                                </td>
                                <td class="col-md-2">
                                    <input type="text" id="fromWhom" class="form-control" [(ngModel)]="refInOutFromWhom"
                                        (ngModelChange)="filterRefList()"
                                        [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                                    <!-- <select class="form-control" id="fromWhom"  [(ngModel)]="refInOutFromWhom" (ngModelChange)="filterRefList()">
                                        <option value=""></option>
                                        <option *ngFor="let inoutfromWhom of InOutRefFromWhomList" [value]="inoutfromWhom">{{inoutfromWhom}}</option>
                                    </select> -->
                                </td>
                                <td class="col-md-2">
                                    <input type="text" id="InoutAdd" class="form-control" [(ngModel)]="refInOutAddress"
                                        (ngModelChange)="filterRefList()"
                                        [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                                    <!-- <select class="form-control" id="InoutAdd"  [(ngModel)]="refInOutAddress" (ngModelChange)="filterRefList()">
                                        <option value=""></option>
                                        <option *ngFor="let inoutadd of InOutRefAddressList" [value]="inoutadd">{{inoutadd}}</option>
                                    </select> -->
                                </td>
                                <td class="col-md-2">
                                    <input type="text" id="inoutsubject" class="form-control"
                                        [(ngModel)]="refInOutSubject" (ngModelChange)="filterRefList()"
                                        [disabled]="InOutWardID>0 && recFrom=='DashBoard'" />
                                    <!-- <select class="form-control" id="inoutsubject"  [(ngModel)]="refInOutSubject" (ngModelChange)="filterRefList()">
                                        <option value=""></option>
                                        <option *ngFor="let inoutsubject of InOutRefSubjectList" [value]="inoutsubject">{{inoutsubject}}</option>
                                    </select> -->
                                </td>
                                <td></td>
                            </tr>

                            <tr *ngFor="let rec of refInOutfilterList">
                                <td>
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="rec.ischecked" />
                                </td>
                                <td>{{rec.inOutDt| date:'dd/MM/yyyy'}} </td>
                                <td>{{rec.inOutNo}}</td>
                                <td>{{rec.addressTo}}</td>
                                <td>{{rec.fromWhom}}</td>
                                <td>{{rec.inOuterAdd}}</td>
                                <td>{{rec.subject}}</td>
                                <td><a href="javascript:void(0)" (click)="ShowInOutImg(rec.inOutID)">View</a></td>
                            </tr>
                        </table>
                    </div>

                </ng-template>
            </ngb-panel>

            <ngb-panel title="Forward Information" id="config-panel-one">
                <ng-template ngbPanelContent>
                    <div class="row">
                        <div class="col-md-4">
                            <label for="Concern-Person">Forward To
                            </label>

                            <select class="form-control" id="selconsperson" #selconsperson [(ngModel)]="forwardUserID"
                                [disabled]="InOutFlag!='InWard'">
                                <ng-container *ngFor="let user of ActiveUserList">
                                    <option *ngIf="user.userID!=SendByUserID" [value]="user.userID">{{user.userName}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-md-8">
                            <label for="Action-Taken">Action Taken By You
                            </label>

                            <input type="text" class="form-control" [(ngModel)]="ActionTaken"
                                [disabled]="InOutFlag!='InWard'" />
                        </div>

                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-4">
                            <label for="Send-By">Select Send By</label>
                            <select class="form-control" id="selsendby" #selWhom [(ngModel)]="SendByUserID"
                                [disabled]=true>
                                <option *ngFor="let user of ActiveUserList" [selected]="user.userID==SendByUserID"
                                    [value]="user.userID">{{user.userName}}</option>
                            </select>
                        </div>
                        <div class="col-md-8">
                            <label for="Remark">Your Remark
                            </label>
                            <input type="text" class="form-control" [(ngModel)]="forwardRemark"
                                [disabled]="InOutFlag!='InWard'" />
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-4">
                            <label for="Send-date">Send Date</label>
                            <!-- <input type="text" class="form-control col-md-6" value="{{SendDate | date:'dd/MM/yyyy'}}"
                                disabled="true" bsDatepicker [bsConfig]="bsdateconfig" /> -->

                                <input type="date" class="form-control" (input)="vlaidateDate($event,'SendDate')" (change)="setDate($event,'SendDate')"
                                disabled="true"  value="{{SendDate}}"  />
                              <span *ngIf="invalid_SendDate!=''" style="font-size: small;color:red">Invalid Date</span>
                        </div>
                        <div class="col-md-8" *ngIf="InOutWardID>0">
                            <div class="row" style="padding-top: 25px;">
                                <div class="col-md-2">
                                    <button type="button" class="form-control btn-primary" (click)="ForwardRec()"
                                        [disabled]="InOutFlag!='InWard'">Forward</button>
                                </div>
                                <div class="col-md-4">
                                    <button type="button" class="form-control btn-primary" (click)="CloseRec()"
                                        [disabled]="InOutFlag!='InWard'">Close In Out Case</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br>
                    <div class="col-md-12">
                        <table class="table table-hover table-responsive-xl labour-table">
                            <thead>

                                <th> </th>
                                <th style="text-align: center;">Previous History</th>
                                <th></th>
                                <th></th>
                            </thead>

                            <thead>
                                <th>Date </th>
                                <th style="text-align: left;"> Name </th>
                                <th>Action Taken</th>
                                <th>Status</th>
                            </thead>

                            <tr class="sub-tr border">
                                <td> </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr *ngFor="let rec of InOutWardActionList">
                                <td>{{rec.sendDate|date:'dd/MM/yyyy'}}</td>
                                <td>{{rec.sendByName}}</td>
                                <td>{{rec.actionTaken}}</td>
                                <td>{{rec.status}}</td>
                            </tr>
                        </table>
                    </div>
                </ng-template>

            </ngb-panel>

            <ngb-panel title="Attachment File Info" id="config-panel-one">
                <ng-template ngbPanelContent>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-hover table-responsive-xl labour-table">
                                <thead>
                                    <!-- <th> </th> -->
                                    <th style="text-align: left;">Document Name </th>
                                    <th></th>
                                    <th></th>
                                </thead>
                                <tr class="sub-tr border">
                                    <td> </td>
                                    <td><input type="file" style="display: inline-block;" id="AttachFile"
                                            accept=".doc,.docx,.xls,.xlsx,.pdf,image/*"
                                            placeholder="Upload file" multiple="false" (change)="setAttachFile($event)"
                                            [disabled]="InOutWardID>0 && recFrom=='DashBoard'">
                                    </td>
                                    <!-- accept=".doc,.docx,.xls,.xlsx,.pdf,audio/*,video/*,image/*" -->
                                    <td><a href="javascript:void(0)" *ngIf="InOutWardID>0"
                                            (click)="ShowInOutImg(InOutWardID)">View Document</a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>

        <br>

        <div class="row" style="text-align: center;">
            <div class="col-md-4" style="margin-top: 27px;">

            </div>
            <div class="col-md-2" style="margin-top: 27px;">
                <button type="button" class="form-control btn-primary" (click)="Save()"
                    [disabled]="InOutWardID>0 && recFrom=='DashBoard'">Submit</button>
            </div>

            <div class="col-md-2" style="margin-top: 27px;">
                <button type="button" class="form-control btn-primary" (click)="Cancel()">Cancel</button>
            </div>

            <div class="col-md-4" style="margin-top: 27px;">

            </div>
        </div>

    </div>

    <div class="container">

        <div class="modal" id="fileModal" [style.display]="showModal_file ? 'block' : 'none'">
            <div class="modal-dialog modal-xl" role="dialog">
                <div class="modal-content">

                    <!-- Modal Header -->
                    <div class="modal-header" style="background-color: #1A5276;color: white;">
                        <h4 class="modal-title">Add New File</h4>
                        <button type="button" class="close" data-dismiss="modal" (click)="hide_modal_file()"
                            style="color: white;">&times;</button>
                    </div>



                    <!-- Modal body -->
                    <div class="modal-body">
                        <div class="row">

                            <div class="col-md-3">
                                <label for="remark">File No </label>
                                <input type="text" id="txtnewfileno" [(ngModel)]="mdlFileNo" value="{{mdlFileNo}}"
                                    class="form-control" placeholder="Enter file no" [disabled]=true />

                            </div>
                            <div class="col-md-6">
                                <label for="remark">File Name </label>
                                <input type="text" id="txtnewfilename" [(ngModel)]="mdlFilenanme"
                                    value="{{mdlFilenanme}}" class="form-control" placeholder="Enter file name" />

                            </div>
                            <div class="col-md-3">
                                <label for="File-Type">Select File Type
                                </label>

                                <select class="form-control" id="selfiletype" #selfiletype [(ngModel)]="mdlFiletype">
                                    <option value="Select File Type">Select File Type</option>
                                    <option value="In">In</option>
                                    <option value="Out">Out</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary"
                        [disabled]="disable_filebupdbtn"  (click)="InsertUpdateFileMaster()">Update</button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
</div>